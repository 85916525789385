import React, { FC } from 'react';
import styled from 'styled-components';
import videoBubblesMp4 from '../../assets/video/Bubbles.mp4';
import videoBubblesWebm from '../../assets/video/Bubbles.webm';
import videoConsoleMp4 from '../../assets/video/Console.mp4';
import videoConsoleWebm from '../../assets/video/Console.webm';
import videoPlanetMp4 from '../../assets/video/Planet.mp4';
import videoPlanetWebm from '../../assets/video/Planet.webm';
import videoRocketMp4 from '../../assets/video/Rocket.mp4';
import videoRocketWebm from '../../assets/video/Rocket.webm';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import BlockButton from '../Shared/BlockButton';
import Breadcrumb from '../Shared/Breadcrumb';
import Button from '../Shared/Button';
import ButtonPink from '../Shared/ButtonPink';
import ContactPhoneNumber from '../Shared/ContactPhoneNumber';
import H1 from '../Shared/H1';
import Image from '../Shared/Image';
import PHero from '../Shared/PHero';
import RowButtons from '../Shared/RowButtons';
import RowHero from '../Shared/RowHero';
import SectionHero from '../Shared/SectionHero';
import Video from '../Shared/Video';
import VideoMaskConsole from '../Shared/VideoMaskConsole';
import VideoMaskPlanet from '../Shared/VideoMaskPlanet';
import VideoMaskRocket from '../Shared/VideoMaskRocket';

type Props = {
  breadcrumbs: any[];
  title?: string;
  description?: string;
  links: any[];
  imageOrVideo: string;
  image?: any;
  video?: string;
  phoneNumber?: string;
};

const HeroPage: FC<Props> = ({
  breadcrumbs,
  title,
  description,
  links,
  imageOrVideo,
  image,
  video,
  phoneNumber,
}) => {
  function renderImageOrVideo() {
    switch (imageOrVideo) {
      case 'image':
      case 'images':
        return (
          image && (
            <ImageContainer>
              <Image image={image} />
            </ImageContainer>
          )
        );
      case 'video':
        switch (video) {
          case variables.videos.bubbles:
            return (
              <VideoContainer isFullWidth>
                <Video autoPlay loop muted playsInline>
                  <source src={videoBubblesWebm} type='video/webm' />
                  <source src={videoBubblesMp4} type='video/mp4' />
                </Video>
              </VideoContainer>
            );
          case variables.videos.console:
            return (
              <VideoContainer>
                <Video autoPlay loop muted playsInline>
                  <source src={videoConsoleWebm} type='video/webm' />
                  <source src={videoConsoleMp4} type='video/mp4' />
                </Video>
                <VideoMaskConsole />
              </VideoContainer>
            );
          case variables.videos.rocket:
            return (
              <VideoContainer isFullWidth>
                <Video autoPlay loop muted playsInline>
                  <source src={videoRocketWebm} type='video/webm' />
                  <source src={videoRocketMp4} type='video/mp4' />
                </Video>
                <VideoMaskRocket />
              </VideoContainer>
            );
          case variables.videos.planet:
            return (
              <VideoContainer>
                <Video autoPlay loop muted playsInline>
                  <source src={videoPlanetWebm} type='video/webm' />
                  <source src={videoPlanetMp4} type='video/mp4' />
                </Video>
                <VideoMaskPlanet />
              </VideoContainer>
            );
          default:
            console.log('Unrecognised selection in video!');
            return null;
        }
      default:
        return null;
    }
  }

  return (
    <SectionHero>
      <RowHero>
        <Block>
          <Header>
            <HeaderBreadcrumb>
              <Breadcrumb breadcrumbs={breadcrumbs} />
            </HeaderBreadcrumb>
            <HeaderContact>
              <ContactPhoneNumber phoneNumber={phoneNumber} />
              <ButtonPink
                onClick={() => {
                  document
                    .getElementById('animated-form')
                    .scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Get in touch
              </ButtonPink>
            </HeaderContact>
          </Header>
        </Block>
        <Block>
          <HeroPageH1>{title}</HeroPageH1>
        </Block>
        <Block mdWidth={2 / 3} lgWidth={1 / 2}>
          <PHero>{description}</PHero>
          {links !== null && (
            <RowButtons>
              {links?.map((link, index) => {
                if (
                  link.title &&
                  link.scrollTo !== null &&
                  link.scrollTo !== 'none'
                ) {
                  return (
                    <BlockButton key={index}>
                      <Button
                        onClick={() =>
                          document
                            .getElementById(link.scrollTo)
                            .scrollIntoView({ behavior: 'smooth' })
                        }
                      >
                        {escapeSpecialCharacters(link.title)}
                      </Button>
                    </BlockButton>
                  );
                }
                if (link.title && link.url) {
                  return (
                    <BlockButton key={index}>
                      <Button as='a' href={link.url}>
                        {escapeSpecialCharacters(link.title)}
                      </Button>
                    </BlockButton>
                  );
                }
              })}
            </RowButtons>
          )}
        </Block>
      </RowHero>
      {renderImageOrVideo()}
    </SectionHero>
  );
};

export default HeroPage;

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const HeaderBreadcrumb = styled.div`
  flex: 1 1 0%;
`;

const HeaderContact = styled.div`
  align-items: center;
  display: none;
  flex: none;
  margin-left: 4rem;

  ${variables.breakpoints.lg} {
    display: flex;
  }
`;

const HeroPageH1 = styled(H1)`
  margin-top: calc(66.666666% - 100px);

  ${variables.breakpoints.md} {
    margin-top: 0;
  }
`;

const ImageContainer = styled.div`
  display: none;

  ${variables.breakpoints.md} {
    display: block;
    height: auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }
`;

type VideoContainerProps = {
  isFullWidth?: boolean;
};

const VideoContainer = styled.div<VideoContainerProps>`
  display: block;
  height: 0;
  padding-bottom: ${(p) => (p.isFullWidth ? '70.833333%' : '100%')};
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${variables.breakpoints.md} {
    padding-bottom: ${(p) => (p.isFullWidth ? '70.833333%' : '50%')};
    top: 50%;
    transform: translateY(-50%);
    width: ${(p) => (p.isFullWidth ? '100%' : '50%')};
  }
`;
