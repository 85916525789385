import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import useViewportWidth from '../../hooks/useViewportWidth';
import Block from '../Shared/Block';
import BlockButton from '../Shared/BlockButton';
import Breadcrumb from '../Shared/Breadcrumb';
import Button from '../Shared/Button';
import ButtonPink from '../Shared/ButtonPink';
import ContactPhoneNumber from '../Shared/ContactPhoneNumber';
import H1 from '../Shared/H1';
import Image from '../Shared/Image';
import ImageBackground from '../Shared/ImageBackground';
import PHero from '../Shared/PHero';
import RowButtons from '../Shared/RowButtons';
import RowHero from '../Shared/RowHero';
import SectionHero from '../Shared/SectionHero';

type Props = {
  breadcrumbs: any[];
  title: string;
  description: string;
  links: any[];
  image: any;
  imageMobile: any;
};

const HeroCaseStudySingle: FC<Props> = ({
  breadcrumbs,
  title,
  description,
  links,
  image,
  imageMobile,
}) => {
  const { isViewportMobile } = useViewportWidth();

  function renderImage() {
    if (isViewportMobile && imageMobile) {
      return <Image image={imageMobile} />;
    } else if (image) {
      return <Image image={image} />;
    } else {
      return null;
    }
  }

  return (
    <HeroCaseStudySection>
      <RowHero>
        <Block>
          <Header>
            <HeaderBreadcrumb>
              <Breadcrumb breadcrumbs={breadcrumbs} />
            </HeaderBreadcrumb>
            <HeaderContact>
              <ContactPhoneNumber />
              <ButtonPink
                onClick={() => {
                  document
                    .getElementById('animated-form')
                    .scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Get in touch
              </ButtonPink>
            </HeaderContact>
          </Header>
        </Block>
        <Block>
          <HeroCaseStudyH1>{title}</HeroCaseStudyH1>
        </Block>
        <Block mdWidth={2 / 3} lgWidth={1 / 2}>
          <PHero>{description}</PHero>
        </Block>
        <Block>
          <RowButtons>
            {links?.map((item, index) => (
              <HeroCaseStudyBlockButton key={index}>
                <Button as={Link} to={item.link.url}>
                  {escapeSpecialCharacters(item.link.title)}
                </Button>
              </HeroCaseStudyBlockButton>
            ))}
          </RowButtons>
        </Block>
      </RowHero>
      <ImageBackground>{renderImage()}</ImageBackground>
    </HeroCaseStudySection>
  );
};

export default HeroCaseStudySingle;

const HeroCaseStudySection = styled(SectionHero)`
  margin-top: -100px;
  padding-top: calc(100px + 2rem);

  &::before {
    background-color: rgba(0, 0, 0, 0.25);
    content: ' ';
    display: block;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
  }

  ${variables.breakpoints.md} {
    margin-top: -132px;
    padding-top: calc(132px + 2rem);
  }

  ${variables.breakpoints.lg} {
    margin-top: 0;
    padding-top: 4rem;
  }
`;

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const HeaderBreadcrumb = styled.div`
  flex: 1 1 0%;
`;

const HeaderContact = styled.div`
  align-items: center;
  display: none;
  flex: none;
  margin-left: 4rem;

  ${variables.breakpoints.lg} {
    display: flex;
  }
`;

const HeroCaseStudyH1 = styled(H1)`
  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['6xl']};
    margin-bottom: 3.5rem;
  }
`;

const HeroCaseStudyBlockButton = styled(BlockButton)`
  ${variables.breakpoints.md} {
    padding-right: 2rem;
    width: 100%;
  }
`;
