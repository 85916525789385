import styled from 'styled-components';
import variables from '../../helpers/variables';
import P from './P';

const PHero = styled(P)`
  margin-bottom: 3rem;

  ${variables.breakpoints.md} {
    margin-bottom: 3.5rem;
  }

  ${variables.breakpoints.lg} {
    margin-bottom: 4rem;
  }
`;

export default PHero;
