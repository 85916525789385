import styled from 'styled-components';
import variables from '../../helpers/variables';

const VideoMaskRocket = styled.div`
  background: linear-gradient(
    180deg,
    rgba(${variables.rgbCS.blue}, 0) 0%,
    ${variables.css.color.blue} 40%
  );
  content: ' ';
  display: block;
  height: 4rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default VideoMaskRocket;
