import React, { FC, Fragment } from 'react';
import HeroCaseStudyArchive from './Flexible/HeroCaseStudyArchive';
import HeroHome from './Flexible/HeroHome';
import HeroPage from './Flexible/HeroPage';
import HeroCaseStudySingle from './Static/HeroCaseStudySingle';

type Props = {
  breadcrumbs: any[];
  location?: any;
  components: any[];
};

const FlexibleHero: FC<Props> = ({ breadcrumbs, location, components }) => {
  return (
    <Fragment>
      {components?.map((component, index) => {
        let fieldType = component.fieldGroupName;
        fieldType = fieldType?.replace('ie_', '');

        switch (fieldType) {
          case 'page_FlexibleHero_FlexibleHero_Home':
            return (
              <HeroHome
                key={index}
                title={component.title}
                description={component.description}
                buttonLabel={component.link?.title}
                buttonUrl={component.link?.url}
              />
            );
          case 'page_FlexibleHero_FlexibleHero_Page':
            return (
              <HeroPage
                key={index}
                breadcrumbs={breadcrumbs}
                title={component.title}
                description={component.description}
                links={component.links}
                imageOrVideo={component.imageOrVideo}
                image={component.image}
                video={component.video}
                phoneNumber={component.phoneNumber}
              />
            );
          case 'page_FlexibleHero_FlexibleHero_CaseStudyArchive':
            return (
              <HeroCaseStudyArchive
                key={index}
                breadcrumbs={breadcrumbs}
                location={location}
                title={component.title}
                description={component.description}
                buttonLabel={component.link?.title}
                buttonUrl={component.link?.url}
              />
            );
          case 'page_FlexibleHero_FlexibleHero_CaseStudy':
            return (
              <HeroCaseStudySingle
                breadcrumbs={breadcrumbs}
                title={component.heroCaseStudy.title}
                description={component.heroCaseStudy.description}
                links={component.heroCaseStudy.links}
                image={component.heroCaseStudy.image}
                imageMobile={component.heroCaseStudy.imageMobile}
              />
            );
          default:
            return <pre>Undefined hero: {component.fieldGroupName}</pre>;
        }
      })}
    </Fragment>
  );
};

export default FlexibleHero;
