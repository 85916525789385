import styled from 'styled-components';
import variables from '../../helpers/variables';

type H1Props = {
  isColorInverted?: boolean;
};

const H1 = styled.h1<H1Props>`
  color: ${(p) =>
    p.isColorInverted ? variables.css.color.blue : variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['4xl']};
  font-weight: ${variables.css.fontWeight.black};
  line-height: ${variables.css.lineHeight};
  margin-bottom: 2.5rem;
  text-transform: uppercase;

  ${variables.breakpoints.sm} {
    font-size: ${variables.css.fontSize['5xl']};
    margin-bottom: 3rem;
  }

  ${variables.breakpoints.md} {
    font-size: ${variables.css.fontSize['6xl']};
    margin-bottom: 3.5rem;
  }

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['7xl']};
    margin-bottom: 4rem;
  }
`;

export default H1;
