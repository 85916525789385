import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import videoBubblesMp4 from '../../assets/video/Bubbles.mp4';
import videoBubblesWebm from '../../assets/video/Bubbles.webm';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Breadcrumb from '../Shared/Breadcrumb';
import ButtonPink from '../Shared/ButtonPink';
import ContactPhoneNumber from '../Shared/ContactPhoneNumber';
import H1 from '../Shared/H1';
import H2 from '../Shared/H2';
import P from '../Shared/P';
import PHero from '../Shared/PHero';
import RowHero from '../Shared/RowHero';
import SectionHero from '../Shared/SectionHero';
import Video from '../Shared/Video';

type Props = {
  breadcrumbs: any[];
  location: any;
  title: string;
  description: string;
  buttonLabel: string;
  buttonUrl: string;
};

const HeroCaseStudyArchive: FC<Props> = ({
  breadcrumbs,
  location,
  title,
  description,
  buttonLabel,
  buttonUrl,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allWpCasestudyType {
        nodes {
          slug
          name
          caseStudies {
            nodes {
              id
            }
          }
        }
      }
    }
  `);

  const base = location.pathname.split('/')[1];

  return (
    <SectionHero>
      <RowHero>
        <Block>
          <Header>
            <HeaderBreadcrumb>
              <Breadcrumb breadcrumbs={breadcrumbs} />
            </HeaderBreadcrumb>
            <HeaderContact>
              <ContactPhoneNumber />
              <ButtonPink
                onClick={() => {
                  document
                    .getElementById('animated-form')
                    .scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Get in touch
              </ButtonPink>
            </HeaderContact>
          </Header>
        </Block>
        <Block>
          <HeroCaseStudyArchiveH1>{title}</HeroCaseStudyArchiveH1>
          <H1 as={H2}>Our Work</H1>
        </Block>
        <Block mdWidth={2 / 3} lgWidth={1 / 2}>
          <PHero>{description}</PHero>
        </Block>
        <BlockButtonFilters>
          <FilterByServicesText>FILTER BY SERVICES</FilterByServicesText>
        </BlockButtonFilters>
        <Block>
          <ButtonFilter
            as={Link}
            to={`/${base}/`}
            isSelected={!location.pathname.includes('/filter/')}
          >
            All
          </ButtonFilter>
          {data.allWpCasestudyType.nodes.map(
            (type, index) =>
              type.caseStudies.nodes.length !== 0 && (
                <ButtonFilter
                  key={index}
                  as={Link}
                  to={`/${base}/filter/${type.slug}/`}
                  isSelected={location.pathname.includes(type.slug)}
                >
                  {escapeSpecialCharacters(type.name)} (
                  {type.caseStudies.nodes.length})
                </ButtonFilter>
              )
          )}
        </Block>
      </RowHero>
      <VideoContainer isFullWidth>
        <Video autoPlay loop muted playsInline>
          <source src={videoBubblesWebm} type='video/webm' />
          <source src={videoBubblesMp4} type='video/mp4' />
        </Video>
      </VideoContainer>
    </SectionHero>
  );
};

export default HeroCaseStudyArchive;

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const HeaderBreadcrumb = styled.div`
  flex: 1 1 0%;
`;

const HeaderContact = styled.div`
  align-items: center;
  display: none;
  flex: none;
  margin-left: 4rem;

  ${variables.breakpoints.lg} {
    display: flex;
  }
`;

const HeroCaseStudyArchiveH1 = styled.h1`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const FilterByServicesText = styled(P)`
  font-weight: ${variables.css.fontWeight.black};
  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize.xl};
    line-height: 2.4375rem;
  }
`;

const BlockButtonFilters = styled(Block)`
  margin-top: 3rem;

  ${variables.breakpoints.md} {
    margin-top: 3.5rem;
  }
`;

type ButtonFilterProps = {
  isSelected: boolean;
};

const ButtonFilter = styled.button<ButtonFilterProps>`
  background-color: ${(p) =>
    p.isSelected ? variables.css.color.pink : 'transparent'};
  border: 1px solid transparent;
  border-color: ${(p) =>
    p.isSelected ? variables.css.color.pink : variables.css.color.white};
  border-radius: 0;
  color: ${variables.css.color.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${variables.css.fontFamily};
  font-weight: ${variables.css.fontWeight.light};
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize.lg};
  }
`;

type VideoContainerProps = {
  isFullWidth?: boolean;
};

const VideoContainer = styled.div<VideoContainerProps>`
  ${variables.breakpoints.md} {
    height: 0;
    padding-bottom: ${(p) => (p.isFullWidth ? '70.833333%' : '50%')};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: ${(p) => (p.isFullWidth ? '100%' : '50%')};
  }
`;
