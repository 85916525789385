import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import videoRocketMp4 from '../../assets/video/Rocket.mp4';
import videoRocketWebm from '../../assets/video/Rocket.webm';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import ButtonPink from '../Shared/ButtonPink';
import ContactPhoneNumber from '../Shared/ContactPhoneNumber';
import H1 from '../Shared/H1';
import PHero from '../Shared/PHero';
import RowHero from '../Shared/RowHero';
import Section from '../Shared/Section';
import Video from '../Shared/Video';
import VideoMaskRocket from '../Shared/VideoMaskRocket';

type Props = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonUrl?: string;
};

const HeroHome: FC<Props> = ({
  title,
  description,
  buttonLabel,
  buttonUrl,
}) => {
  // TODO: Animations
  // ParallaxScroll for text. No limits?
  // AnimatedHeading - convert to a render prop for more scalability.
  // Timed animations?

  return (
    <HeroHomeWrapper>
      <SectionHome>
        <RowHero>
          <HeaderContact>
            <ContactPhoneNumber />
            <ButtonPink
              onClick={() => {
                document
                  .getElementById('animated-form')
                  .scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Get in touch
            </ButtonPink>
          </HeaderContact>
          <Block>
            <HeroHomeH1>{title}</HeroHomeH1>
          </Block>
          <Block mdWidth={2 / 3} lgWidth={1 / 2}>
            <PHero>{description}</PHero>
            {buttonLabel && buttonUrl && (
              <Button as={Link} to={buttonUrl}>
                {escapeSpecialCharacters(buttonLabel)}
              </Button>
            )}
          </Block>
        </RowHero>
        <VideoContainer>
          <Video autoPlay loop muted playsInline>
            <source src={videoRocketWebm} type='video/webm' />
            <source src={videoRocketMp4} type='video/mp4' />
          </Video>
          <VideoMaskRocket />
        </VideoContainer>
      </SectionHome>
    </HeroHomeWrapper>
  );
};

export default HeroHome;

const HeroHomeWrapper = styled.div`
  position: relative;
`;

const SectionHome = styled(Section)`
  padding-top: 14rem;
  position: static;

  ${variables.breakpoints.xxl} {
    padding-bottom: calc(70.833333% - 14rem);
    height: 0;
  }
`;

const HeaderContact = styled.div`
  align-items: center;
  display: none;
  position: absolute;
  right: 0;
  top: -10rem;

  ${variables.breakpoints.lg} {
    display: flex;
  }
`;

const HeroHomeH1 = styled(H1)`
  font-size: ${variables.css.fontSize['5xl']};
  margin-bottom: 3rem;
  margin-top: calc(${70.833333 * 1.9}% - 300px);
  width: min-content;

  ${variables.breakpoints.md} {
    font-size: ${variables.css.fontSize['7xl']};
    margin-top: 0;
  }

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['8xl']};
  }
`;

const VideoContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: ${70.833333 * 1.9}%;
  position: absolute;
  right: 0;
  top: -100px;
  width: ${100 * 1.9}%;

  ${variables.breakpoints.md} {
    padding-bottom: 70.833333%;
    left: 0;
    top: -132px;
    width: 100%;
  }

  ${variables.breakpoints.lg} {
    top: 0;
  }
`;
